import { API_URL } from "../defaults";
import $api from "../http";
import { BuyTicketsDto } from "../models/dto/buy-tickets.dto";
import { CreateRaffleDto } from "../models/dto/create-raffle.dto";
import { ControllerName } from "../types";

export class ApiService {

  constructor() {}

  static data = {
    getParseData: async () => {
      const data = await $api.get("https://api-new.mbc.family/core/landing/data");
      return data;
    }
  };

  static merch = {
    getMyOrders: async () => {
      return await $api.get(this.buildUrl(ControllerName.MERCH, `orders`));
    },
    createOrder: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.MERCH, `orders`), dto);
    },
    updateOrder: async (id: number, dto: any) => {
      return await $api.put(this.buildUrl(ControllerName.MERCH, `orders/${id}`), dto);
    },
    deleteOrder: async (id: number) => {
      return await $api.delete(this.buildUrl(ControllerName.MERCH, `orders/${id}`));
    },
    getMerchProducts: async () => {
      return await $api.get(this.buildUrl(ControllerName.MERCH, `products`));
    }
  }

  static users = {
    checkNameExists: async (name: string) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `username/check/${name}`));
    },
    handleUserCollection: async (collId: number) => {
      return await $api.get(this.buildUrl(ControllerName.USERS, `collection/handle/by/${collId}`));
    },
    exportNfts: async (mint_addrs: string = "") => {
      return await $api.post(this.buildUrl(ControllerName.USERS, `nfts/table/generate`), {mint_addrs});
    }
  };

  static tourneys = {
    getOffTourneys: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `tourney/get`));
      return data;
    },
    addOffTourney: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `tourney/add`), dto);
      return data;
    },
    updateOffTourney: async (id: number, dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.MBC, `tourney/update/${id}`), dto);
      return data;
    },
    deleteOffTourney: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.MBC, `tourney/delete/${id}`));
      return data;
    },
    regToOffTourney: async (id: number, dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `tourney/reg`), dto);
      return data;
    }
  };

  static chats = {
    signInChat: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.CHATS, `sign`), dto);
      return data;
    },
    createChatRoom: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.CHATS, `create`), dto);
      return data;
    },
    deleteChatRoom: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.CHATS, `list/${id}`));
      return data;
    },
    getTelegramFeed: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.CHATS, `posts`));
      return data;
    },
    getTelegramFeedImage: async (name: string) => {
      const data = await $api.get(this.buildUrl(ControllerName.CHATS, `posts/image/${name}`));
      return data;
    },
    getMyUnreaded: async (userId: number) => {
      const data = await $api.post(this.buildUrl(ControllerName.CHATS, `myunread`), {userId});
      return data;
    }
  }

  static drops = {
    getUniverses: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.DROPS, `universes`));
      return data;
    },
    refreshUniverses: async () => {
      const data = await $api.post(this.buildUrl(ControllerName.DROPS, `universes`));
      return data;
    },
  }

  static mbc = {
    getAllUsers: async (searchText = "", page = 0, limit = 15) => {
      const data = await $api.get(this.buildUrl(ControllerName.USERS, `list${this.buildParams({search: searchText, page, limit})}`));
      return data;
    },
    getUserInfo: async (id: number) => {
      const data = await $api.get(this.buildUrl(ControllerName.USERS, `account/byid/${id}`));
      return data;
    },
    getNfts: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `nft/get`));
      return data;
    },
    getAllCollections: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.USERS, `collections/all`));
      return data;
    },
    getAllDrops: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.USERS, `drops/all`));
      return data;
    },
    addNft: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `nft/add`), dto);
      return data;
    },
    updateNft: async (id: number, dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.MBC, `nft/update/${id}`), dto);
      return data;
    },
    deleteNft: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.MBC, `nft/delete/${id}`));
      return data;
    },
    getMintInfo: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.MBC, `mint/info`));
      return data;
    },
    updateMintInfo: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `mint/info`), dto);
      return data;
    },
    sendCustomizeRequest: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.MBC, `customize/send`), dto);
      return data;
    },
    signIn: async (dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.USERS, `signin`), dto);
      return data;
    },
    updateAccount: async (dto: any) => {
      const data = await $api.put(this.buildUrl(ControllerName.USERS, `account`), dto);
      return data;
    },
    findAccountNfts: async (dto: any) => {},
    getUsersBankInfo: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.USERS, `account/bankinfo`));
      return data;
    },
    getUsersTop: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.USERS, `list/top`));
      return data;
    },
    getVotings: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.VOTINGS, `list`));
      return data;
    },
    voteVoting: async (id: number, dto: any) => {
      const data = await $api.post(this.buildUrl(ControllerName.VOTINGS, `list/${id}`), dto);
      return data;
    },
    uploadCollection: async (dto: {userId: number, nfts: any[]}) => {
      const data = await $api.post(this.buildUrl(ControllerName.USERS, `collection/upload`), dto);
      return data;
    },
    updateCollection: async (dto: {userId: number, newnfts: any[], oldnfts: any[]}) => {
      const data = await $api.post(this.buildUrl(ControllerName.USERS, `collection/update`), dto);
      return data;
    },
    exportToXlsx: async (dto: any) => {
      return await $api.post(this.buildUrl(ControllerName.MBC, `export/xlsx/`), dto);
    }
  };

  static raffles = {
    getRaffles: async () => {
      const data = await $api.get(this.buildUrl(ControllerName.RAFFLES, `list`));
      return data;
    },
    createRaffle: async (dto: CreateRaffleDto) => {
      const data = await $api.post(this.buildUrl(ControllerName.RAFFLES, `list`), dto);
      return data;
    },
    updateRaffle: async (id: number, dto: CreateRaffleDto) => {
      const data = await $api.put(this.buildUrl(ControllerName.RAFFLES, `list/${id}`), dto);
      return data;
    },
    deleteRaffle: async (id: number) => {
      const data = await $api.delete(this.buildUrl(ControllerName.RAFFLES, `list/${id}`));
      return data;
    },
    buyRaffleTickets: async (id: number, dto: BuyTicketsDto) => {
      const data = await $api.post(this.buildUrl(ControllerName.RAFFLES, `list/${id}`), dto);
      return data;
    }
  };

  static buildUrl(
    controller: ControllerName,
    method: string
  ) {
    return `${API_URL}${controller}/${method}`
  }

  static buildUrl2(
    controller: ControllerName,
    method: string
  ) {
    return `${controller}/${method}`
  }

  static buildParams(map: any) {
    let query = "";
    for (let key in map) {
      if (query == "") {
        query = `?${key}=${map[key]}`;
      } else {
        query += `&${key}=${map[key]}`;
      }
    }
    return query;
  }

}