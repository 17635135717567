import moment from 'moment';

export class Logger {

  /**
   * System Constants
   */
  public static isDev = true;
  public static prefix: string = "MBC";

  /**
   * Admin Side Constants
   */

  constructor() {}

  public static logRequest(req: Request, res: Response, next: any) {
    Logger.log(`Request -> ${req.method} -> ${req.url}`);
    next();
  }

  public static olog(msg: any) {
    const date = moment().format('DD MMM, HH:mm:ss');
    if (this.isDev) {
      console.log(
        this.prefix + ' Logs DATA [' + date + '] ',
        msg
      );
    }
  }

  public static log(msg: any, obj: any = null) {
    const date = moment().format('DD MMM, HH:mm:ss');
    if (this.isDev) {
      if (obj === null) {
        let sec = msg;
        if (msg instanceof Object || msg instanceof Array) {
          sec = JSON.stringify(msg);
        }
        console.log(
          this.prefix + ' Logs [' + date + '] ' + sec
        );
      } else {
        let sec = obj;
        if (obj instanceof Object || obj instanceof Array) {
          sec = JSON.stringify(obj);
        }
        console.log(
          this.prefix + ' Logs [' + date + '] ' + msg,
          sec
        );
      }
    }
  }

  public static elog(msg: any, obj: any = null) {
    const date = moment().format('DD MMM, HH:mm:ss');
    if (this.isDev) {
      if (obj === null) {
        let sec = msg;
        if (msg instanceof Object || msg instanceof Array) {
          sec = JSON.stringify(msg);
        }
        console.log(
          this.prefix + ' Logs [' + date + '] ' + sec
        );
      } else {
        let sec = obj;
        if (obj instanceof Object || obj instanceof Array) {
          sec = JSON.stringify(obj);
        }
        console.log(
          this.prefix + ' Logs [' + date + '] ' + msg,
          sec
        );
      }
    }
  }
  
  public static wlog(msg: any, obj: any = null) {
    const date = moment().format('DD MMM, HH:mm:ss');
    if (this.isDev) {
      if (obj === null) {
        let sec = msg;
        if (msg instanceof Object || msg instanceof Array) {
          sec = JSON.stringify(msg);
        }
        console.log(
          this.prefix + ' Logs [' + date + '] ' + sec
        );
      } else {
        let sec = obj;
        if (obj instanceof Object || obj instanceof Array) {
          sec = JSON.stringify(obj);
        }
        console.log(
          this.prefix + ' Logs [' + date + '] ' + msg,
          sec
        );
      }
    }
  }

  public static slog(msg: any, obj: any = null) {
    const date = moment().format('DD MMM, HH:mm:ss');
    if (this.isDev) {
      if (obj === null) {
        let sec = msg;
        if (msg instanceof Object || msg instanceof Array) {
          sec = JSON.stringify(msg);
        }
        console.log(
          this.prefix + ' Logs [' + date + '] ' + sec
        );
      } else {
        let sec = obj;
        if (obj instanceof Object || obj instanceof Array) {
          sec = JSON.stringify(obj);
        }
        console.log(
          this.prefix + ' Logs [' + date + '] ' + msg,
          sec
        );
      }
    }
  }

  public static clog(msg: any, obj: any) {
    const date = moment().format('DD MMM, HH:mm:ss');
    if (this.isDev) {
      console.log('[' + date + '] ' + msg, obj);
    }
  }

  public static timeDifference(current: number, IAPIevious: number) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - IAPIevious;

    if (elapsed < msPerMinute) {
      return Math.round(elapsed / 1000) + " секунд";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " минут";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " часов";
    } else if (elapsed < msPerMonth) {
      return "apIAPIoximately " + Math.round(elapsed / msPerDay) + " дней";
    } else if (elapsed < msPerYear) {
      return "apIAPIoximately " + Math.round(elapsed / msPerMonth) + " месяцев";
    } else {
      return "apIAPIoximately " + Math.round(elapsed / msPerYear) + " лет";
    }
  }

}
