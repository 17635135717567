import moment from "moment";
import { API_URL } from "./defaults";
import { ControllerName } from "./types"
import 'moment/locale/ru';
import * as anchor from '@project-serum/anchor';
import { FilterDropsItems, FilterFamilyItems, FilterUniverseItems } from "./utils/account";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { useMemo } from "react";
import { PhantomWalletAdapter } from "@solana/wallet-adapter-phantom";
import { clusterApiUrl } from "@solana/web3.js";

export const getDateFormat = (date: string, format: string = "DD MMM YYYY, HH:mm") => {
  moment.lang("ru");
  moment.locale("ru");
  return moment(date).format(format);
}

export const getDateTSFormat = (ts: number, format: string = "DD MMM YYYY, HH:mm") => {
  return moment(ts).format(format);
}

export function Utf8ArrayToStr(array: any) {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while(i < len) {
  c = array[i++];
  switch(c >> 4)
  { 
    case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
      // 0xxxxxxx
      out += String.fromCharCode(c);
      break;
    case 12: case 13:
      // 110x xxxx   10xx xxxx
      char2 = array[i++];
      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
      break;
    case 14:
      // 1110 xxxx  10xx xxxx  10xx xxxx
      char2 = array[i++];
      char3 = array[i++];
      out += String.fromCharCode(((c & 0x0F) << 12) |
                     ((char2 & 0x3F) << 6) |
                     ((char3 & 0x3F) << 0));
      break;
  }
  }

  return out;
}

export const getFilterBy = (items: any[], type: "drop" | "family" | "universe", value: string) => {
  if (type == "drop") {
    const drp = FilterDropsItems.find((filter: any) => filter.value == value);
    if (isNullOrUndefined(drp)) {
      return {value: "all", name: "Все", nameEn: "All"};
    } else {
      return drp;
    }
  } else if (type == "family") {
    return FilterFamilyItems.find((filter: any) => filter.value == value)
  } else {
    return items.find((filter: any) => filter.value == value)
  }
}

export const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
export const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
export const connectionAc = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

export const buildUrl = (
  controller: ControllerName,
  method: string
) => {
  return `${API_URL}${controller}/${method}`
}

export const fixNumber = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

export const buildParams = (map: any) => {
  let query = "";
  for (let key in map) {
    if (query == "") {
      query = `?${key}=${map[key]}`;
    } else {
      query += `&${key}=${map[key]}`;
    }
  }
  return query;
}

export function urlify(text: string) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  })
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function declOfNum(n: number, text_forms: string[]) {  
  n = Math.abs(n) % 100; 
  var n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2]; }
  if (n1 > 1 && n1 < 5) { return text_forms[1]; }
  if (n1 == 1) { return text_forms[0]; }
  return text_forms[2];
}

export const statuses = [
  {key: "whale", name: "Whale", proc: 25, sum: 0, need: 120, selected: false},
  {key: "orca", name: "Orca", proc: 20, sum: 0, need: 50, selected: false},
  {key: "shark", name: "Shark", proc: 15, sum: 0, need: 36, selected: false},
  {key: "marlin", name: "Marlin", proc: 10, sum: 0, need: 18, selected: false},
  {key: "dolphin", name: "Dolphin", proc: 5, sum: 0, need: 7, selected: false},
  {key: "guppy", name: "Guppy", proc: 0, sum: 0, need: 0, selected: true}
];

export function getUserRnkLeast(len: number) {
  let sttss = [...statuses];
  sttss.forEach((st) => {
    st.selected = false;
  });
  let nftlng = len;
  if (nftlng < 7) {
    sttss[5].selected = true;
  } else if (nftlng >= 7 && nftlng < 18) {
    sttss[4].selected = true;
  } else if (nftlng >= 18 && nftlng < 36) {
    sttss[3].selected = true;
  } else if (nftlng >= 36 && nftlng < 50) {
    sttss[2].selected = true;
  } else if (nftlng >= 50 && nftlng < 120) {
    sttss[1].selected = true;
  } else if (nftlng >= 120) {
    sttss[0].selected = true;
  }
  return sttss.find(v => v.selected);
}

export const getUserRnk = (collection: any) => {
  let sttss = [...statuses];
  sttss.forEach((st: any) => {
    st.selected = false;
  });
  if (!isNullOrUndefined(collection) && !isNullOrUndefined(collection.nfts)) {
    let nftlng = collection.nfts.length;
    if (nftlng < 7) {
      sttss[5].selected = true;
    } else if (nftlng >= 7 && nftlng < 18) {
      sttss[4].selected = true;
    } else if (nftlng >= 18 && nftlng < 36) {
      sttss[3].selected = true;
    } else if (nftlng >= 36 && nftlng < 50) {
      sttss[2].selected = true;
    } else if (nftlng >= 50 && nftlng < 120) {
      sttss[1].selected = true;
    } else if (nftlng >= 120) {
      sttss[0].selected = true;
    }
  } else {
    sttss[5].selected = true;
  }
  return sttss.find(v => v.selected);
}