export const MintTranslations = {
  "EN": {
    mint_title: "MINT FORM",
    mint_balance: "Your balance",
    wl_balance: "WL Balance",
    mint_select: "Select Wallet",
    mint_connect: "Connect Wallet",
    mint_action: "MINT",
    mint_accessed: "AVAILABLE",
    mint_success: "Mint Successfull!",
    mint_action_wl: "WL MINT"
  },
  "RU": {
    mint_title: "МИНТ ФОРМА",
    mint_balance: "Ваш баланс",
    wl_balance: "Баланс WL",
    mint_select: "Выбрать кошелек",
    mint_connect: "Подключить кошелек",
    mint_action: "МИНТ",
    mint_accessed: "ДОСТУПНО",
    mint_success: "Минт прошел успешно!",
    mint_action_wl: "WL МИНТ"
  }
}