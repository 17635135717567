import { PublicKey } from "@solana/web3.js";
import { toast } from "react-toastify";
import { LOCALES } from "../i18n/locales";
import { asyncForEach, isNullOrUndefined } from "../utils";

export interface FilterItem {
  value: string;
  name: string;
  nameEn: string;
  search?: string;
  image?: string;
}

export const StatsModel: any = {
  guppy: 0,
  dolphin: 0,
  marlin: 0,
  shark: 0,
  orca: 0,
  whale: 0
};

export interface CollectionItemProps {
  name: string;
  nameEn: string;
  value: string;
  items: {image: string}[];
  swiper?: any;
}

export const CollectionItems: CollectionItemProps[] = [
  {
    name: "Аниме",
    nameEn: "Anime",
    value: "anime",
    items: [
      {image: "./assets/images/drops/collections/anime/0.jpg"},
      {image: "./assets/images/drops/collections/anime/1.jpg"},
      {image: "./assets/images/drops/collections/anime/2.jpg"},
      {image: "./assets/images/drops/collections/anime/3.jpg"},
      {image: "./assets/images/drops/collections/anime/4.jpg"},
      {image: "./assets/images/drops/collections/anime/5.jpg"},
      {image: "./assets/images/drops/collections/anime/6.jpg"},
      {image: "./assets/images/drops/collections/anime/7.jpg"},
      {image: "./assets/images/drops/collections/anime/8.jpg"},
      {image: "./assets/images/drops/collections/anime/9.jpg"},
      {image: "./assets/images/drops/collections/anime/10.jpg"},
      {image: "./assets/images/drops/collections/anime/11.jpg"}
    ]
  }, {
    name: "Мстители",
    nameEn: "The Avengers",
    value: "avengers",
    items: [
      {image: "./assets/images/drops/collections/avengers/0.jpg"},
      {image: "./assets/images/drops/collections/avengers/1.jpg"},
      {image: "./assets/images/drops/collections/avengers/2.jpg"},
      {image: "./assets/images/drops/collections/avengers/3.jpg"},
      {image: "./assets/images/drops/collections/avengers/4.jpg"},
      {image: "./assets/images/drops/collections/avengers/5.jpg"},
      {image: "./assets/images/drops/collections/avengers/6.jpg"},
      {image: "./assets/images/drops/collections/avengers/7.jpg"},
      {image: "./assets/images/drops/collections/avengers/8.jpg"}
    ]
  }, {
    name: "Дисней",
    nameEn: "Disney",
    value: "disney",
    items: [
      {image: "./assets/images/drops/collections/disney/0.jpg"},
      {image: "./assets/images/drops/collections/disney/1.jpg"},
      {image: "./assets/images/drops/collections/disney/2.jpg"},
      {image: "./assets/images/drops/collections/disney/3.jpg"},
      {image: "./assets/images/drops/collections/disney/4.jpg"},
      {image: "./assets/images/drops/collections/disney/5.jpg"},
      {image: "./assets/images/drops/collections/disney/6.jpg"},
      {image: "./assets/images/drops/collections/disney/7.jpg"},
      {image: "./assets/images/drops/collections/disney/8.jpg"},
      {image: "./assets/images/drops/collections/disney/9.jpg"},
      {image: "./assets/images/drops/collections/disney/10.jpg"},
      {image: "./assets/images/drops/collections/disney/11.jpg"},
      {image: "./assets/images/drops/collections/disney/12.jpg"},
      {image: "./assets/images/drops/collections/disney/13.jpg"},
      {image: "./assets/images/drops/collections/disney/14.jpg"}
    ]
  }, {
    name: "TMNT",
    nameEn: "TMNT",
    value: "tmnt",
    items: [
      {image: "./assets/images/drops/collections/tmnt/0.jpg"},
      {image: "./assets/images/drops/collections/tmnt/1.jpg"},
      {image: "./assets/images/drops/collections/tmnt/2.jpg"},
      {image: "./assets/images/drops/collections/tmnt/3.jpg"},
      {image: "./assets/images/drops/collections/tmnt/4.jpg"},
      {image: "./assets/images/drops/collections/tmnt/5.jpg"},
      {image: "./assets/images/drops/collections/tmnt/6.jpg"},
      {image: "./assets/images/drops/collections/tmnt/7.jpg"}
    ]
  }, {
    name: "Мортал Комбат",
    nameEn: "Mortal Kombat",
    value: "mortal kombat",
    items: [
      {image: "./assets/images/drops/collections/mortal_kombat/0.jpg"},
      {image: "./assets/images/drops/collections/mortal_kombat/1.jpg"},
      {image: "./assets/images/drops/collections/mortal_kombat/2.jpg"},
      {image: "./assets/images/drops/collections/mortal_kombat/3.jpg"},
      {image: "./assets/images/drops/collections/mortal_kombat/4.jpg"},
      {image: "./assets/images/drops/collections/mortal_kombat/5.jpg"},
      {image: "./assets/images/drops/collections/mortal_kombat/6.jpg"},
      {image: "./assets/images/drops/collections/mortal_kombat/7.jpg"}
    ]
  }, {
    name: "Советское кино",
    nameEn: "Soviet cinema",
    value: "Soviet",
    items: [
      {image: "./assets/images/drops/collections/soviet_cinema/0.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/1.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/2.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/3.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/4.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/5.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/6.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/7.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/8.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/9.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/10.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/11.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/12.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/13.jpg"},
      {image: "./assets/images/drops/collections/soviet_cinema/14.jpg"}
    ]
  }, {
    name: "Отряд Самоубийц",
    nameEn: "Suicide Squad",
    value: "Suicide squad",
    items: [
      {image: "./assets/images/drops/collections/suicide_squad/0.jpg"},
      {image: "./assets/images/drops/collections/suicide_squad/1.jpg"},
      {image: "./assets/images/drops/collections/suicide_squad/2.jpg"},
      {image: "./assets/images/drops/collections/suicide_squad/3.jpg"}
    ]
  }, {
    name: "Властелин Колец",
    nameEn: "The Lord of the Rings",
    value: "lord",
    items: [
      {image: "./assets/images/drops/collections/lotr/0.jpg"},
      {image: "./assets/images/drops/collections/lotr/1.jpg"},
      {image: "./assets/images/drops/collections/lotr/3.jpg"},
      {image: "./assets/images/drops/collections/lotr/5.jpg"},
      {image: "./assets/images/drops/collections/lotr/6.jpg"},
      {image: "./assets/images/drops/collections/lotr/7.jpg"},
      {image: "./assets/images/drops/collections/lotr/8.jpg"},
      {image: "./assets/images/drops/collections/lotr/9.jpg"}
    ]
  }, {
    name: "Лига Справедливости",
    nameEn: "Justice League",
    value: "Justice League",
    items: [
      {image: "./assets/images/drops/collections/justice_league/0.jpg"},
      {image: "./assets/images/drops/collections/justice_league/1.jpg"},
      {image: "./assets/images/drops/collections/justice_league/2.jpg"},
      {image: "./assets/images/drops/collections/justice_league/3.jpg"},
      {image: "./assets/images/drops/collections/justice_league/4.jpg"},
      {image: "./assets/images/drops/collections/justice_league/5.jpg"},
      {image: "./assets/images/drops/collections/justice_league/6.jpg"},
      {image: "./assets/images/drops/collections/justice_league/7.jpg"},
      {image: "./assets/images/drops/collections/justice_league/8.jpg"}
    ]
  }, {
    name: "Люди X",
    nameEn: "X-Men",
    value: "x-men",
    items: [
      {image: "./assets/images/drops/collections/xmen/0.jpg"},
      {image: "./assets/images/drops/collections/xmen/1.jpg"},
      {image: "./assets/images/drops/collections/xmen/2.jpg"},
      {image: "./assets/images/drops/collections/xmen/3.jpg"},
      {image: "./assets/images/drops/collections/xmen/4.jpg"},
      {image: "./assets/images/drops/collections/xmen/5.jpg"},
      {image: "./assets/images/drops/collections/xmen/6.jpg"}
    ]
  }, {
    name: "Звездные войны",
    nameEn: "StarWars",
    value: "star wars",
    items: [
      {image: "./assets/images/drops/collections/starwars/0.jpg"},
      {image: "./assets/images/drops/collections/starwars/1.jpg"},
      {image: "./assets/images/drops/collections/starwars/2.jpg"},
      {image: "./assets/images/drops/collections/starwars/3.jpg"},
      {image: "./assets/images/drops/collections/starwars/4.jpg"},
      {image: "./assets/images/drops/collections/starwars/5.jpg"},
      {image: "./assets/images/drops/collections/starwars/6.jpg"},
      {image: "./assets/images/drops/collections/starwars/7.jpg"},
      {image: "./assets/images/drops/collections/starwars/8.jpg"},
      {image: "./assets/images/drops/collections/starwars/9.jpg"},
      {image: "./assets/images/drops/collections/starwars/10.jpg"}
    ]
  }, {
    name: "Гарри Поттер",
    nameEn: "Harry Potter",
    value: "harry potter",
    items: [
      {image: "./assets/images/drops/collections/harry_potter/0.jpg"},
      {image: "./assets/images/drops/collections/harry_potter/1.jpg"},
      {image: "./assets/images/drops/collections/harry_potter/2.jpg"},
      {image: "./assets/images/drops/collections/harry_potter/3.jpg"},
      {image: "./assets/images/drops/collections/harry_potter/4.jpg"},
      {image: "./assets/images/drops/collections/harry_potter/5.jpg"}
    ]
  }, {
    name: "8-bit",
    nameEn: "8-bit",
    value: "8-bit",
    items: [
      {image: "./assets/images/drops/collections/8bit/0.jpg"},
      {image: "./assets/images/drops/collections/8bit/1.jpg"},
      {image: "./assets/images/drops/collections/8bit/2.jpg"}
    ]
  }, {
    name: "Игра в кальмара",
    nameEn: "Squid Game",
    value: "squid game",
    items: [
      {image: "./assets/images/drops/collections/squidgame/0.jpg"},
      {image: "./assets/images/drops/collections/squidgame/1.jpg"},
      {image: "./assets/images/drops/collections/squidgame/2.jpg"},
      {image: "./assets/images/drops/collections/squidgame/3.jpg"},
      {image: "./assets/images/drops/collections/squidgame/4.jpg"},
      {image: "./assets/images/drops/collections/squidgame/5.jpg"}
    ]
  }
];

export const FilterDropsItemsLC: FilterItem[] = [
  {value: "all", name: "All", nameEn: "All"},
  {value: "1", name: "1", nameEn: "1"},
  {value: "2", name: "2", nameEn: "2"},
  {value: "3", name: "3", nameEn: "3"},
  {value: "4", name: "4", nameEn: "4"},
  {value: "5", name: "5", nameEn: "5"},
  {value: "6", name: "6", nameEn: "6"},
  {value: "7", name: "7", nameEn: "7"},
  {value: "8", name: "8", nameEn: "8"},
  {value: "9", name: "9", nameEn: "9"},
  {value: "10", name: "10", nameEn: "10"},
  {value: "11", name: "11", nameEn: "11"}
];

export const FilterDropsItems: FilterItem[] = [
  {value: "1", name: "1", nameEn: "1"},
  {value: "2", name: "2", nameEn: "2"},
  {value: "3", name: "3", nameEn: "3"},
  {value: "4", name: "4", nameEn: "4"},
  {value: "5", name: "5", nameEn: "5"},
  {value: "6", name: "6", nameEn: "6"},
  {value: "7", name: "7", nameEn: "7"},
  {value: "8", name: "8", nameEn: "8"},
  {value: "9", name: "9", nameEn: "9"},
  {value: "10", name: "10", nameEn: "10"},
  {value: "11", name: "11", nameEn: "11"},
  {value: "12", name: "12", nameEn: "12"},
  {value: "13", name: "13", nameEn: "13"}
];

export const FilterFamilyItems: FilterItem[] = [
  {image: "", value: "all", name: "Все", nameEn: "All"},
  {image: "/assets/images/drops/drop1/0/2.webp", value: "joker", name: "Джокеры", nameEn: "Joker"},
  {image: "/assets/images/drops/drop1/0/1.webp", value: "fox", name: "Лисы", nameEn: "Foxes"},
  {image: "/assets/images/drops/drop1/1/1.webp", value: "hippos", name: "Бегемоты", nameEn: "Hippos"},
  {image: "/assets/images/drops/drop1/2/1.webp", value: "rhinos", name: "Носороги", nameEn: "Rhinos"},
  {image: "/assets/images/drops/drop1/3/1.webp", value: "bear", name: "Медведи", nameEn: "The Bears"},
  {image: "/assets/images/drops/drop1/4/1.webp", value: "tigers", name: "Тигры", nameEn: "Tigers"},
  {image: "/assets/images/drops/drop1/5/1.webp", value: "wolves", name: "Волки", nameEn: "Wolves"},
  {image: "/assets/images/drops/drop1/6/1.webp", value: "lions", name: "Львы", nameEn: "Lions"},
  {image: "/assets/images/drops/drop1/7/1.webp", value: "elephants", name: "Слоны", nameEn: "Elephants"},
  {image: "/assets/images/drops/drop1/8/1.webp", value: "turtles", name: "Черепахи", nameEn: "Turtles"},
  {image: "/assets/images/drops/drop1/9/1.webp", value: "wild boars", name: "Кабаны", nameEn: "Boars"},
  {image: "/assets/images/drops/drop1/10/1.webp", value: "bulls", name: "Быки", nameEn: "Bulls"},
  {image: "/assets/images/drops/drop1/11/1.webp", value: "monkey", name: "Обезьяны", nameEn: "Monkeys"}
];

export const FilterUniverseItems: FilterItem[] = [
  {image: "", search: "all", value: "all", name: "Все", nameEn: "All"},
  {image: "/assets/images/drops/collections/avengers/0.jpg", search: "avengers", value: "avengers", name: "The Avengers", nameEn: "The Avengers"},
  {image: "/assets/images/drops/collections/tmnt/0.jpg", search: "TMNT", value: "TMNT", name: "TMNT", nameEn: "TMNT"},
  {image: "/assets/images/drops/collections/suicide_squad/0.jpg", search: "Suicide squad", value: "Suicide squad", nameEn: "Suicide squad", name: "Отряд Самоубийц"},
  {image: "/assets/images/drops/collections/mortal_kombat/0.jpg", search: "Mortal Kombat", value: "Mortal Kombat", nameEn: "Mortal Kombat", name: "Мортал Комбат"},
  {image: "/assets/images/drops/collections/disney/0.jpg", search: "disney", value: "disney", nameEn: "Disney", name: "Дисней"},
  {image: "/assets/images/drops/collections/lotr/0.jpg", search: "lord", value: "lord", nameEn: "The Lord of the Rings", name: "Властелин Колец"},
  {image: "/assets/images/drops/collections/soviet_cinema/0.jpg", search: "Soviet", value: "Soviet cinema", nameEn: "Soviet cinema", name: "Советское кино"},
  {image: "/assets/images/drops/collections/justice_league/0.jpg", search: "Justice League", value: "Justice League", nameEn: "Justice League", name: "Лига Справедливости"},
  {image: "/assets/images/drops/collections/anime/0.jpg", search: "Anime", value: "Anime", nameEn: "Anime", name: "Аниме"},
  {image: "/assets/images/drops/collections/8bit/0.jpg", search: "8 bit", value: "8 bit", nameEn: "8 bit", name: "8 bit"},
  {image: "/assets/images/drops/collections/xmen/0.jpg", search: "x-men", value: "x-men", nameEn: "X-Men", name: "Люди X"},
  {image: "/assets/images/drops/collections/harry_potter/0.jpg", search: "Harry Potter", value: "harry_potter", nameEn: "Harry Potter", name: "Гарри Поттер"},
  {image: "/assets/images/drops/collections/starwars/0.jpg", search: "star wars", value: "star wars", nameEn: "StarWars", name: "Звездные войны"},
  {image: "/assets/images/drops/collections/squidgame/0.jpg", search: "Squid Game", value: "Squid Game", nameEn: "Squid Game", name: "Игра в Кальмара"}
];

export interface UserStatus {
  key: string;
  name: string;
  proc: number;
  sum: number;
  need: number;
  selected: boolean;
}

export const AllStatuses: UserStatus[] = [
  {key: "whale", name: "Whale", proc: 25, sum: 0, need: 120, selected: false},
    {key: "orca", name: "Orca", proc: 20, sum: 0, need: 50, selected: false},
    {key: "shark", name: "Shark", proc: 15, sum: 0, need: 36, selected: false},
    {key: "marlin", name: "Marlin", proc: 10, sum: 0, need: 18, selected: false},
    {key: "dolphin", name: "Dolphin", proc: 5, sum: 0, need: 7, selected: false},
    {key: "guppy", name: "Guppy", proc: 0, sum: 0, need: 0, selected: true}
];

export const getTopListTitle = (key: string) => {
  switch (key) {
    case "whale":
      return "Whales TOP";
    case "orca":
      return "Orca's TOP";
    case "shark":
      return "Sharks TOP";
    case "marlin":
      return "Marlins TOP";
    case "dolphin":
      return "Dolphins TOP";
    case "guppy":
      return "Guppies TOP";
    default:
      return "TOP"
  }
}

export const getParsedDate = (ts: number) => {
  const time = ts / 1000;
  let hours = (time / 60 / 60).toFixed(2);
  return hours.split(".").join(" час. ") + " мин.";
}

export const getResultedRow = (results: any[], varia: string) => {
  if (results.length > 0) {
    const vr = results.find(v => v.title == varia);
    if (!isNullOrUndefined(vr)) {
      return vr.procent;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

export const getResultedRowWidth = (results: any[], varia: string) => {
  if (results.length > 0) {
    const vr = results.find(v => v.title == varia);
    if (!isNullOrUndefined(vr)) {
      return vr.procent == "0" ? 2 : parseInt(vr.procent);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
}

export const getUserRank = (statuses: any[]) => {
  return statuses.find(v => v.selected);
}

export const isEng = (locale: string) => {
  return locale == LOCALES.ENGLISH;
}

export const onCopy = (locale: string) => {
  toast.success(isEng(locale) ? "Address copied successfully!" : "Адрес успешно скопирован!");
}

export const ReleasedDrops = ["drop1", "drop2", "drop3", "drop4", "drop5", "drop6", "drop7", "drop8", "drop9", "drop10", "drop11"];

interface Nft {
    mint: string;
    updateAuthority: string;
    data: {
        creators: any[];
        name: string;
        symbol: string;
        uri: string;
        sellerFeeBasisPoints: number;
    };
    enque_data: any;
    key: any;
    primarySaleHappened: boolean;
    isMutable: boolean;
    editionNonce: number;
    masterEdition?: string;
    edition?: string;
}

export const checkNftsChanged = async (newNfts: any[], oldNfts: Nft[]) => {
  let nfts = [...oldNfts];
  let changes = [
    {name: "Без изменений", items: [] as Nft[]},
    {name: "Отсутствуют", items: [] as Nft[]},
    {name: "Новые", items: [] as Nft[]}
  ];

  newNfts.forEach((nft: any) => {
    const ix = nfts.findIndex(v => v.data.uri == nft.uri);

    if (ix != -1) {
      changes[0].items.push(nfts[ix]);
    } else {
      changes[2].items.push(nft);
    }
  });

  oldNfts.forEach((nft: Nft) => {
    const ix = newNfts.findIndex(v => v.uri == nft.data.uri);
    if (ix == -1) {
      changes[1].items.push(nft);
    }
  });

  await asyncForEach(changes[2].items, async (item: any, ix: number) => {
    const res = await fetch(item.uri, {});
    const json = await res.json();
    console.log(json);
    changes[2].items[ix].enque_data = json;
  });

  console.log("changes");
  console.log(changes);

  return changes;
}

export const fillCollsNil = (colls: any) => {
  const names = ["guppy", "dolphin", "marlin", "shark", "orca", "whale"];

  names.forEach(name => {
    colls[name] = colls[name] == 0 ? 0 : colls[name];
  });
  
  return colls;
}

export const extraNumSort = (a: number, b: number, align: number) => {
  return a > b ? (align == 1 ? 1 : -1) : (a < b ? (align == -1 ? 1 : -1) : 0);
}

export const fillUserSocials = (user: any) => {
  if (!isNullOrUndefined(user.place) && user.place != "") {
    let socs = user.place.split(";");
    user.socials = {telegram: socs[0], instagram: socs[1], discord: socs[2], twitter: socs[3]};
  } else {
    user.socials = {telegram: "", instagram: "", discord: "", twitter: ""};
  }
  return user;
}

export const getProvider = () => {
  const hard = localStorage.getItem("hard_login");
  if (!isNullOrUndefined(hard)) {
    return {
      publicKey: new PublicKey(hard)
    };
  }

  if ("solana" in window) {
    const provider = (window as any).solana;
    if (provider.isPhantom) {
      provider.connect();
      return provider;
    }
  }
}

interface IsActiveOrPubKeyGetted {
  state: number;
}

export async function interFastProvider(): Promise<IsActiveOrPubKeyGetted> {
  return new Promise<IsActiveOrPubKeyGetted>((resolve, reject) => {
    let tryx = 0;
    const interFast = setInterval(async () => {
      const provider = getProvider();
      if (provider == undefined) {
        if (tryx > 20) {
          resolve({state: 1});
          clearInterval(interFast);
        } else {
          tryx += 1;
        }
      } else {
        if (provider.publicKey != undefined) {
          resolve({state: 2});
          clearInterval(interFast);
        }
      }
    }, 100);
  });
}

export const getReleasedDrops = (data: any) => {
  let drops: any[] = [];
  ReleasedDrops.forEach((drop: string) => {
    drops.push(data[drop]);
  });
  return drops;
}