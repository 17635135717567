import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { ApiService } from "../services/api";
import moment from "moment";
import { isNullOrUndefined } from "../utils";
import { LOCALES } from "../i18n/locales";
import { faqEng, faqRu, menuEn, menuRu, StorageKey } from "../defaults";

export default class Store {

  isMobile: boolean = false;
  isAuth = false;
  isCloseSocket = false;
  token: string = "";
  userAuthData: any = {};

  reactChatPosts: any[] = [];

  allDropsList: any | undefined = [];

  locale: string = LOCALES.ENGLISH;
  chatVisible: boolean = true;
  homeVisible: boolean = true;
  faq: any[] = faqEng;
  menu: any[] = menuEn;

  collections_filter: any[] = [];

  isActiveA: boolean = false;
  portfolioRendered: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setIsMobile(bl: boolean) {
    this.isMobile = bl;
  }

  setCollectionFilter(items: any[]) {
    this.collections_filter = items;
    localStorage.setItem(StorageKey.COLL_FILTER, JSON.stringify(items));
  }

  setPortfolioRendered(bl: boolean) {
    this.portfolioRendered = bl;
  }

  setHomeVisible(homeVisible: boolean) {
    this.homeVisible = homeVisible;
  }

  setChatVisible(chatVisible: boolean) {
    this.chatVisible = chatVisible;
  }

  setIsActiveA(bl: boolean) {
    this.isActiveA = bl;
  }

  setLocale(locale: string) {
    this.locale = locale;
    if (locale == LOCALES.RUSSIAN) {
      this.faq = faqRu;
      this.menu = menuRu;
    } else {
      this.faq = faqEng;
      this.menu = menuEn;
    }
  }

  setFaq(lst: any[]) {
    this.faq = lst;
  }

  setMenu(lst: any[]) {
    this.menu = lst;
  }

  setAllDropsList(drops: any) {
    this.allDropsList = drops;
  }

  setReactChatPosts(posts: any[]) {
    this.reactChatPosts = posts;
    localStorage.setItem(StorageKey.CHAT_POSTS, JSON.stringify(posts));
  }

  isRunDropsAlgAccess() {
    const now = moment().unix();
    if (
      isNullOrUndefined(this.userAuthData.last_update_collection) ||
      this.userAuthData.last_update_collection == 0 ||
      (this.userAuthData.last_update_collection + 43200) < now
    ) {
      return true;
    } else {
      return false;
    }
  }

  getDropsAlgTimer() {
    const now = moment().unix();
    if (
      isNullOrUndefined(this.userAuthData.last_update_collection) ||
      this.userAuthData.last_update_collection == 0 ||
      (this.userAuthData.last_update_collection + 43200) < now
    ) {
      return 0;
    } else {
      let tms = (this.userAuthData.last_update_collection + 43200) - now;
      let hrs = tms / 60 / 60;
      return `${hrs.toFixed(0)}ч.`;
    }
  }

  setupUserStorage(data: any) {
    localStorage.setItem(StorageKey.USER_AUTHED, "true");
    localStorage.setItem(StorageKey.USER_DATA, JSON.stringify(data.user));
    localStorage.setItem(StorageKey.USER_TOKEN, data.token);
    localStorage.setItem(StorageKey.USER_AUTH_TIME, `${moment().unix()}`);

    this.setUserData(data.user);
    this.setToken(data.token);
    this.setIsAuth(true);
  }

  setIsAuth(auth: boolean) {
    this.isAuth = auth;
  }

  getReactChatPosts() {
    const posts = localStorage.getItem("chat_posts");
    if (!isNullOrUndefined(posts)) {
      let psts = JSON.parse(posts);
      this.reactChatPosts = psts;
      return psts;
    } else {
      return [];
    }
  }
  
  setUserData(data: any) {
    this.userAuthData = data;
  }
  
  setIsCloseSocket(data: boolean) {
    this.isCloseSocket = data;
  }

  isUserNotified() {
    return !isNullOrUndefined(this.userAuthData.socket_id) && this.userAuthData.socket_id != "";
  }

  getLogin(login: string) {
    return `${login.substr(0, 4)}...${login.substr(login.length - 4, login.length)}`;
  }

  getMyLogin() {
    return this.isAuth ? `${this.userAuthData.login.substr(0, 4)}...${this.userAuthData.login.substr(this.userAuthData.login.length - 4, this.userAuthData.login.length)}` : ``;
  }

  getGenesisBy(collection: any) {
    if (isNullOrUndefined(collection)) {
      return 0;
    }

    const nfts = collection.nfts;
    let count = 0;

    if (nfts.length == 0) {
      return 0;
    }
    
    nfts.forEach((nft: any) => {
      if (isNullOrUndefined(nft.enque_data.attributes)) {
        return;
      }

      const attr = nft.enque_data.attributes.find((v: any) => v.trait_type.toLowerCase() == "drop");

      if (attr.value == "1" || attr.value == "2") {
        count += 1;
      }
    });

    return count;
  }

  getDropsCollected() {
    if (isNullOrUndefined(this.userAuthData.collection)) {
      return [];
    }

    const colls = this.userAuthData.collection.nfts;

    let drops: any[] = [];
    colls.forEach((nft: any) => {
      if (isNullOrUndefined(nft.enque_data.attributes)) {
        return;
      }

      const attrDrop = nft.enque_data.attributes.find((v: any) => v.trait_type == "Drop");
      if (attrDrop !== undefined) {
        const ix = drops.find((v: any) => v == attrDrop.value);
        if (ix === undefined && parseInt(attrDrop.value) <= 10) {
          drops.push(attrDrop.value);
        }
      }
    });

    return drops;
  }

  isInCollectedDrops(drop: string) {
    const drops = this.getDropsCollected();
    return drops.find(v => v == drop) != undefined;
  }

  getCollectedUniverses(): {universes: any[], collected: any[]} {
    if (isNullOrUndefined(this.userAuthData.collection)) {
      return {universes: [], collected: []};
    }

    const colls = this.userAuthData.collection.nfts;

    let universes: any[] = [], drops: any[] = [];
    colls.forEach((nft: any) => {
      if (isNullOrUndefined(nft.enque_data.attributes)) {
        return;
      }
      const attr = nft.enque_data.attributes.find((v: any) => v.trait_type == "Universe"); // "Universe"

      if (attr !== undefined && attr.value != "Unreveal") {
        //console.log(nft.enque_data.attributes);
        let ix = universes.findIndex((v: any) => v.name == attr.value);
        if (ix != -1) {
          universes[ix].items.push(nft);
        } else {
          universes.push({
            name: attr.value,
            items: [nft]
          });
        }
      }
    });
    
    const collected = universes.filter(v => v.items.length >= 8);
    
    return {universes, collected};
  }

  getCollectionMultiplier() {
    if (isNullOrUndefined(this.userAuthData.collection)) {
      return "0.0";
    }

    const colls = this.userAuthData.collection.nfts;
    let multi = "0.0";

    colls.forEach((nft: any) => {
      if (isNullOrUndefined(nft.enque_data.attributes)) {
        return;
      }

      const attr = nft.enque_data.attributes.find((v: any) => v.trait_type.toLowerCase() == "multiplier");
      
      if (attr.value == "Unreveal") {
        return;
      }

      multi = `${(parseFloat(multi) + parseFloat(attr.value)).toFixed(1)}`;
    });

    let universes: any[] = [], drops: any[] = [];
    colls.forEach((nft: any) => {
      if (isNullOrUndefined(nft.enque_data.attributes)) {
        return;
      }

      const attrDrop = nft.enque_data.attributes.find((v: any) => v.trait_type == "Drop");
      if (attrDrop !== undefined) {
        const ix = drops.find((v: any) => v == attrDrop.value);
        if (ix === undefined && parseInt(attrDrop.value) <= 10) {
          drops.push(attrDrop.value);
        }
      }
      
      const attr = nft.enque_data.attributes.find((v: any) => v.trait_type == "Universe"); // "Universe"

      if (attr !== undefined && attr.value != "Unreveal") {
        //console.log(nft.enque_data.attributes);
        let ix = universes.findIndex((v: any) => v.name == attr.value);
        if (ix != -1) {
          universes[ix].items.push(nft);
        } else {
          universes.push({
            name: attr.value,
            items: [nft]
          });
        }
      }
    });

    drops = drops.sort((a: any, b: any) => {
      if (parseInt(a) > parseInt(b)) {
        return 1;
      } else if (parseInt(a) < parseInt(b)) {
        return -1;
      } else {
        return 0;
      }
    });

    const mxmz = universes.filter(v => v.items.length >= 8);
    if (mxmz != undefined) {
      let plus = 0, minus = 0;
      mxmz.forEach((uni: any) => {
        
        uni.items.forEach((item: any) => {
          if (!isNullOrUndefined(item.enque_data)) {
            const attr = item.enque_data.attributes.find((v: any) => v.trait_type.toLowerCase() == "multiplier");
        
            if (attr.value == "Unreveal") {
              return;
            }
  
            plus += parseFloat(attr.value) * 3;
            minus += parseFloat(attr.value);
          }
        });
      });
      
      multi = `${(parseFloat(multi) + plus) - minus}`;
    }

    if (drops.length == 10) {
      multi = `${(parseFloat(multi) + ((parseFloat(multi) / 100) * 30)).toFixed(1)}`;
    }

    return multi;
  }

  getCollectionMultiplierBy(coll: any) {
    if (isNullOrUndefined(coll)) {
      return "0.0";
    }

    const colls = coll.nfts;
    let multi = "0.0";

    colls.forEach((nft: any) => {
      if (isNullOrUndefined(nft.enque_data.attributes)) {
        return;
      }

      const attr = nft.enque_data.attributes.find((v: any) => v.trait_type.toLowerCase() == "multiplier");
      
      if (attr.value == "Unreveal") {
        return;
      }

      multi = `${(parseFloat(multi) + parseFloat(attr.value)).toFixed(1)}`;
    });

    let universes: any[] = [], drops: any[] = [];
    colls.forEach((nft: any) => {
      if (isNullOrUndefined(nft.enque_data.attributes)) {
        return;
      }

      const attrDrop = nft.enque_data.attributes.find((v: any) => v.trait_type == "Drop");
      if (attrDrop !== undefined) {
        const ix = drops.find((v: any) => v == attrDrop.value);
        if (ix === undefined && parseInt(attrDrop.value) <= 10) {
          drops.push(attrDrop.value);
        }
      }
      
      const attr = nft.enque_data.attributes.find((v: any) => v.trait_type == "Universe");

      if (attr !== undefined && attr.value != "Unreveal") {
        //console.log(nft.enque_data.attributes);
        let ix = universes.findIndex((v: any) => v.name == attr.value);
        if (ix != -1) {
          universes[ix].items.push(nft);
        } else {
          universes.push({
            name: attr.value,
            items: [nft]
          });
        }
      }
    });

    drops = drops.sort((a: any, b: any) => {
      if (parseInt(a) > parseInt(b)) {
        return 1;
      } else if (parseInt(a) < parseInt(b)) {
        return -1;
      } else {
        return 0;
      }
    });
    
    const mxmz = universes.filter(v => v.items.length >= 8);
    if (mxmz != undefined) {
      let plus = 0, minus = 0;
      mxmz.forEach((uni: any) => {
        
        let pls = 0;
        uni.items.forEach((item: any) => {
          if (!isNullOrUndefined(item.enque_data)) {
            const attr = item.enque_data.attributes.find((v: any) => v.trait_type.toLowerCase() == "multiplier");
        
            if (attr.value == "Unreveal") {
              return;
            }
  
            plus += parseFloat(attr.value) * 3;
            minus += parseFloat(attr.value);
          }
        });
      });
      multi = `${(parseFloat(multi) + plus) - minus}`;
    }
    
    if (drops.length == 10) {
      multi = `${(parseFloat(multi) + ((parseFloat(multi) / 100) * 30)).toFixed(1)}`;
    }

    return multi;
  }
  
  getCollectionBy(userData: any) {
    if (userData.collection != null) {
      return {
        size: userData.collection.nfts.length,
        nfts: userData.collection.nfts,
        cans: userData.collection.nfts.filter((v: any) => !isNullOrUndefined(v.enque_data) && !isNullOrUndefined(v.enque_data.attributes) && v.enque_data.attributes.length > 2 && v.enque_data.attributes[1].value == "Unreveal")
      };
    } else {
      return {
        size: 0,
        nfts: [],
        cans: []
      }
    }
  }

  getCollection() {
    if (this.userAuthData.collection != null) {
      return {
        size: this.userAuthData.collection.nfts.length,
        nfts: this.userAuthData.collection.nfts,
        cans: this.userAuthData.collection.nfts.filter((v: any) => !isNullOrUndefined(v.enque_data) && !isNullOrUndefined(v.enque_data.attributes) && v.enque_data.attributes.length > 2 && v.enque_data.attributes[1].value == "Unreveal")
      };
    } else {
      return {
        size: 0,
        nfts: [],
        cans: []
      }
    }
  }

  setToken(token: string) {
    this.token = token;
  }

  getUserData() {
    return localStorage.getItem("user_data");
  }

  isUserAccessed() {
    const data = localStorage.getItem("user_data");
    if (isNullOrUndefined(data)) {
      return false;
    }

    try {
      const user = JSON.parse(data);
      if (user.collection.nfts.length > 0) {
        return true;
      } else {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  isUserAuthed() {
    const data = localStorage.getItem("user_data");
    if (isNullOrUndefined(data)) {
      return false;
    }
    return true;
  }

  isUserPaid() {
    return this.userAuthData.role == "USER_PAID";
  }

}