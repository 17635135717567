export const DEFAULT_AVATAR = "https://core.mbc.family/uploads/avatars/default_avatar.png";

export const IS_DEV = false;
export const IS_TW = false;

//export const CHAT_URL = "http://localhost:3535";
export const CHAT_URL = "https://srv1.top-racing.ru";

//export const API_URL = "http://localhost:3939";
export const API_URL = "https://api-new.mbc.family";

//export const ADDR_URL = "http://localhost:3000";
export const ADDR_URL = "https://top-racing.tv";

export const menu: any[] = [
  {
    title: "Главная",
    icon: "fa-home",
    offsetM: 0
  },{
    title: "Достоинства проекта",
    icon: "fa-stars",
    offsetM: 660
  },{
    title: "Уникальность",
    icon: "fa-gem",
    offsetM: 1470
  },{
    title: "Текущая оценка портфеля",
    icon: "fa-cash-register",
    offsetM: 2222
  },{
    title: "Дорожная карта",
    icon: "fa-road",
    offsetM: 3030
  },{
    title: "Команда",
    icon: "fa-users",
    offsetM: 3878
  },{
    title: "FAQ",
    icon: "fa-question",
    offsetM: 4538
  }
];

export const faqEng: any[] = [
  {
    title: "1. What is mint?",
    content: "<b>MINT</b> <span>(from Eng – minting)</span>, IS THE PROCESS OF NFT CREATING IN THE BLOCKCHAIN, AN INITIAL SALE FROM THE AUTHOR.",
    openned: false, opacity: "0", height: 84
  }, {
    title: "2. What is WL TOKEN?",
    content: "<b>WL</b> <span>(white list) token</span> - IS A TOKEN WITHOUT WHICH YOU CANNOT PARTICIPATE IN A CLOSED NFT SALE (MINT)",
    openned: false, opacity: "0", height: 78
  }, {
    title: "3. Where can I buy/sell your NFT?",
    content: "AFTER THE SECOND DROP MINT (SERIES) IS OVER, THE COLLECTION WILL BE PLACED ON THE MAGIC EDEN MARKETPLACE (<a href='https://magiceden.io/marketplace/metabuild' target='_blank'>HTTPS://MAGICEDEN.IO/</a>).",
    openned: false, opacity: "0", height: 96
  }, {
    title: "4. What is STAKING?",
    content: "<b>STAKING</b> - IS A METHOD OF PASSIVE EARNING IN WHICH USERS LOCK TOKENS IN A PROTOCOL (A SMART CONTRACT) ON THE PROOF OF STAKE ALGORITHM.",
    openned: false, opacity: "0", height: 100
  }, {
    title: "5. How to distribute the profit among the participants?",
    content: "EVERY 180 DAYS, A MECHANISM FOR DISTRIBUTING CURRENT INVESTMENT PROFITS AMONG ALL NFT HOLDERS WILL BE IMPLEMENTED THROUGH A SMART CONTRACT. FUNDS WILL BE CREDITED TO SOL AND ONLY TO NFT THAT ARE IN STAKING AT THE TIME OF DISTRIBUTION. ONE WEEK BEFORE THE SNAPSHOT (FIXING WALLET ADDRESSES FOR DISTRIBUTION), AN ANNOUNCEMENT WILL BE PUBLISHED IN ALL COMMUNITY INFORMATION CHANNELS. <br><br>IN DISTRIBUTION DAO PROFITS:<br><br>1 NFT = k / n OF THE TOTAL DISTRIBUTION FUND, WHERE N IS THE NUMBER OF TOKENS IN CIRCULATION AT THE TIME OF DISTRIBUTION, K IS THE MULTIPLIER FACTOR FOR THE RARITY OF A PARTICULAR NFT.",
    openned: false, opacity: "0", height: 376
  }, {
    title: "6. What is the value of your NFT?",
    content: "THE MAIN VALUE OF NFT OWNING IS THE ABILITY TO CO-INVEST OF A COMMON DAO FUND, ACCESS DIRECTLY TO A CLOSED COMMUNITY TO SHARE EXPERIENCES AND USEFUL INFORMATION, RECEIVE A DISTRIBUTION OF PROFITS FROM INVESTING. EACH ART IS INDIVIDUALLY CREATED BY THE ARTIST, AND IT HAS UNIQUE ATTRIBUTES, THAT GIVES HIGH ARTISTIC VALUE TO EACH TOKEN OF THE COLLECTION.",
    openned: false, opacity: "0", height: 252
  }, {
    title: "7. Where can I learn more about the project?",
    content: "YOU CAN FIND THE PROJECT ROADMAP AND WHITE PAPER AT <a href='https://metabuildersclub.io'>metabuildersclub.io</a>",
    openned: false, opacity: "0", height: 76
  }, {
    title: "8. What is DAO?",
    content: "<b>Decentralized Autonomous Organization (DAO)</b> - IS AN ORGANIZATIONAL FORM IN WHICH THE COORDINATION OF PARTICIPANTS’ ACTIVITIES AND RESOURCE MANAGEMENT IS CARRIED OUT IN ACCORDANCE WITH A PRE-AGREED AND FORMALIZED SET OF RULES, THE CONTROL OVER COMPLIANCE WITH WHICH IS PERFORMED AUTOMATICALLY. THE RULES OF DAO OPERATION CAN BE SET IN THE SMART CONTRACTS. RECORDS OF DAO FINANCIAL TRANSACTIONS AND THE SOFTWARE RULES OF SUCH CONTRACTS ARE STORED IN THE TRANSACTION BLOCKCHAIN.",
    openned: false, opacity: "0", height: 312
  }, {
    title: "9. Why do you need to burn/destroy NFTs?",
    content: "When burned, the total market supply of NFTs will decrease - respectively, both the unit value and the share in the distribution of profits for the remaining NFTs will increase.",
    openned: false, opacity: "0", height: 136
  }, {
    title: "10. Why can't I buy NFT with my card?",
    content: "THIS FUNCTION IS CURRENTLY UNDER DEVELOPMENT.",
    openned: false, opacity: "0", height: 56
  }, {
    title: "11. How to join the club?",
    content: "IN ORDER TO JOIN THE CLUB, YOU MUST BUY NFT OF THE COMMUNITY",
    openned: false, opacity: "0", height: 56
  }, {
    title: "12. What is the history of the NFTs or what are they pegged to?",
    content: "OUR NFTs ARE NOT PEGGED TO ANYTHING, BUT THEIR VALUE IS ENSURED AT LEAST BY THE EXISTENCE OF AN INVESTMENT FUND.",
    openned: false, opacity: "0", height: 76
  }, {
    title: "13. When is the next DROP?",
    content: "EACH DROP WILL BE ANNOUNCED IN ADVANCE IN THE SOCIAL NETWORKS OF THE PROJECT. CHECK FOR UPDATES: <br><br><a href='https://metabuildersclub.io' class='white' target='_blank'>site</a><br><a href='https://twitter.com/BuildersMeta' class='white' target='_blank'>twitter</a><br><a href='https://discord.gg/5yw9jUZxtV' class='white' target='_blank'>discord</a><br><a href='https://t.me/metabuilders_nft' class='white' target='_blank'>telegram</a>",
    openned: false, opacity: "0", height: 176
  }, {
    title: "14. Why can't I pay from the KuCoin / Binance exchange, in short from any other exchange, namely from the PHANTOM wallet?",
    content: "IF YOU PAY DIRECTLY FROM THE EXCHANGE HOT WALLET, IN A REVERSE TRANSACTION YOU WILL RECEIVE NFTs. THEN TO GET ACCESS TO THEM YOU WILL NEED TO START A DIALOGUE WITH EXCHANGE SUPPORT SERVICE. IN THE BEST CASE YOU WILL GET ACCESS TO YOUR NFTs AFTER A LONG TIME AND LONG CORRESPONDENCE WITH EXCHANGE TECHNICAL SUPPORT SERVICE, IN THE WORST CASE YOU WILL NOT GET ANY ACCESS ACCORDING TO THE RULES OF A PARTICULAR SITE.",
    openned: false, opacity: "0", height: 236
  }, {
    title: "15. Is it compulsory to buy a VR headset to be a member of the community?",
    content: "VR HEADSET IS REQUIRED IN ORDER TO PARTICIPATE IN VR COMMUNITY MEETINGS. TO PARTICIPATE IN THE COMMUNITY, YOU ONLY NEED TO BECOME A NFT OWNER.",
    openned: false, opacity: "0", height: 96
  }
];

export const faqRu: any[] = [
  {
    title: "1. Что такое минт?",
    content: "<b>MINT</b> <span>(c англ. - чеканка)</span>, минт -  процесс создания NFT в блокчейне, первичная продажа от автора.",
    openned: false, opacity: "0", height: 84
  }, {
    title: "2. Что такое WL токен?",
    content: "<b>WL</b> <span>(white list) токен</span> - токен, без которого вы не можете участвовать в закрытой продаже (минте) NFT",
    openned: false, opacity: "0", height: 78
  }, {
    title: "3. Где я могу купить/продать ваши NFT?",
    content: "После окончания минта второго дропа (серия) коллекция будет размещена на маркетплэйсе Magic Eden (<a href='https://magiceden.io/marketplace/metabuild' target='_blank'>https://magiceden.io/</a>).",
    openned: false, opacity: "0", height: 96
  }, {
    title: "4. Что такое стэйкинг?",
    content: "<b>Стейкинг</b> - это способ пассивного заработка, при котором пользователи блокируют токены в протоколе (смарт-контракте) на алгоритме Proof of Stake (PoS).",
    openned: false, opacity: "0", height: 100
  }, {
    title: "5. Как распределяется прибыль между участниками?",
    content: "Каждые 180 дней через смартконтракт будет реализован механизм распределения текущей прибыли от инвестиций среди всех холдеров NFT. <br>Средства будут начисляться в SOL и только на NFT, которые находятся в стэйкинге на момент распределения. <br>За неделю до снэпшота (фиксация адресов кошельков для распределения) будет опубликовано сообщение во всех информационных каналах комьюнити.<br><br>При распределении прибыли DAO:<br><br>1 NFT = k / n  от общего фонда распределения, где n - количество токенов в обращении на момент распределения, k - коэффициент мультипликатор редкости конкретного NFT.",
    openned: false, opacity: "0", height: 376
  }, {
    title: "6. В чём ценность ваших NFT?",
    content: "Основная ценность владения NFT заключается возможность совместного инвестирования общего фонда DAO, доступ непосредственно в закрытое комьюнити для обмена опытом и полезной информацией, получать распределение прибыли от инвестирования.<br>Также каждый арт создается художников индивидуально и имеет уникальные атрибуты, что придает высокую художественную ценность каждому токену коллекции.",
    openned: false, opacity: "0", height: 252
  }, {
    title: "7. Где ознакомиться  более подробно с проектом?",
    content: "С дорожной картой и white paper проекта вы можете ознакомиться на сайте: <a href='https://metabuildersclub.io'>metabuildersclub.io</a>",
    openned: false, opacity: "0", height: 76
  }, {
    title: "8. Что такое DAO?",
    content: "<b>Децентрализованная автономная организация (ДАО), Decentralized Autonomous Organization (DAO)</b> - организационная форма, при которой координация деятельности участников и управление ресурсами происходит в соответствии с заранее согласованным и формализованным набором правил, контроль за соблюдением которых выполняется автоматически. Правила работы ДАО могут быть заданы в смарт-контрактах. Записи финансовых транзакций ДАО и программные правила таких контрактов хранятся в цепочке блоков транзакций.",
    openned: false, opacity: "0", height: 312
  }, {
    title: "9. Зачем нужно сжигать/уничтожать NFT?",
    content: "При сжигании будет уменьшаться общее рыночное предложение NFT - соответственно будет расти как удельная стоимость, так доля при распределении прибыли на оставшиеся NFT.",
    openned: false, opacity: "0", height: 136
  }, {
    title: "10. Почему я не могу купить NFT с помощью карты?",
    content: "В данный момент эта функция находится в разработке.",
    openned: false, opacity: "0", height: 56
  }, {
    title: "11. Как вступить в клуб?",
    content: "Для того чтобы вступить в клуб необходимо приобрести NFT сообщества",
    openned: false, opacity: "0", height: 56
  }, {
    title: "12. Какая история у NFT, или к чему они привязаны?",
    content: "Наши NFT ни к чему не привязаны, но их стоимость обеспечена как минимум наличием инвестиционного фонда.",
    openned: false, opacity: "0", height: 76
  }, {
    title: "13. Когда будет следующий дроп?",
    content: "О каждом дропе будет сообщено заранее в социальных сетях проекта. Следите за обновлениями:<br><br><a href='https://metabuildersclub.io' class='white' target='_blank'>сайт</a><br><a href='https://twitter.com/BuildersMeta' class='white' target='_blank'>твиттер</a><br><a href='https://discord.gg/5yw9jUZxtV' class='white' target='_blank'>дискорд</a><br><a href='https://t.me/metabuilders_nft' class='white' target='_blank'>телеграм</a>",
    openned: false, opacity: "0", height: 176
  }, {
    title: "14. А почему я не могу оплатить с биржи Кукоин/Бинанс, короче с любой другой, а именно с кошелька Phantom?",
    content: "Если вы оплатите непосредственно с горячего кошелька биржи, то в обратной транзакции вы получите NFT. Далее чтобы получить к ним доступ - вам нужно будет начать диалог с поддержкой биржи. В лучшем случае вы получите доступ к своим NFT через продолжительное время и долгую переписку с технической поддержкой биржи, в худшем случае - доступ вам не будет предоставлен исходя из правил той или иной площадки.",
    openned: false, opacity: "0", height: 236
  }, {
    title: "15. Обязательно ли покупать VR шлем, чтобы быть участником сообщества?",
    content: "VR шлем необходим чтобы участвовать в VR встречах членов комьюнити. Для участия в сообществе необходимо только стать владельцем NFT.",
    openned: false, opacity: "0", height: 96
  }
];

export const menuRu: any[] = [
  {title: "Главная", icon: "fa-home", offsetM: 0},
  {title: "Текущая оценка портфеля", icon: "fa-cash-register", offsetM: 9992},
  {title: "Кастомизация NFT", icon: "fa-gears", offsetM: 9999},
  {title: "Дропы", icon: "fa-input-numeric", offsetM: 9991},
  {title: "Мерч", icon: "fa-shirt", offsetM: 9993},
  {title: "WhitePaper", icon: "fa-scroll", offsetM: 9997},
  {title: "Наш ФК", icon: "fa-futbol", offsetM: 9994}
];
export const menuEn: any[] = [
  {title: "Home", icon: "fa-home", offsetM: 0},
  {title: "Сurrent portfolio valuation", icon: "fa-cash-register", offsetM: 9992},
  {title: "Customize NFT", icon: "fa-gears", offsetM: 9999},
  {title: "Drops", icon: "fa-input-numeric", offsetM: 9991},
  {title: "Merch", icon: "fa-shirt", offsetM: 9993},
  {title: "WhitePaper", icon: "fa-scroll", offsetM: 9997},
  {title: "Our Football Club", icon: "fa-futbol", offsetM: 9994}
];

export const enum StorageKey {
  LANG = "lang_init",
  USER_AUTHED = "user_authed",
  USER_DATA = "user_data",
  USER_TOKEN = "user_token",
  USER_AUTH_TIME = "user_auth_time",
  CHAT_POSTS = "chat_posts",
  HARD_LOGIN = "hard_login",
  WALLET_NAME = "walletName",
  COLL_FILTER = "collections_filter"
}