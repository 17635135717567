import { useAnchorWallet, useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { observer } from "mobx-react-lite";
import { FC, useContext, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import { Context } from "../..";
import Modal from "../../components/Modal";
import { faqEng, faqRu, menuEn, menuRu, StorageKey } from "../../core/defaults";
import { LOCALES } from "../../core/i18n/locales";
import { isNullOrUndefined } from "../../core/utils";
import styles from "../../styles/SettingsWidget.module.scss";

interface SettingsWidgetProps {
  onLocaleChange?: any
}

const SettingsWidget: FC<SettingsWidgetProps> = ({
  onLocaleChange
}) => {

  const navigate = useNavigate();

  const { store, reactChatStore } = useContext(Context);
  
  const [isSettingVisible, setIsSettingVisible] = useState(false);
  const [oldAddress, setOldAddress] = useState("");
  const [isNewAccountSelected, setIsNewAccountSelected] = useState(false);
  const [isSelectedNull, setIsSelectedNull] = useState(false);

  const anchorWallet = useAnchorWallet();
  const { connect, connected, connecting, publicKey, wallet, select } = useWallet();

  useEffect(() => {
    if (!isSelectedNull) {
      //select(null)
      setIsSelectedNull(true);
    }

    if (connected) {
      if (oldAddress == "" && !isNullOrUndefined(publicKey)) {
        setOldAddress(publicKey?.toString());
      }

      if (!isNullOrUndefined(store.userAuthData.login) && store.userAuthData.login != publicKey?.toString()) {
        localStorage.setItem(StorageKey.HARD_LOGIN, publicKey?.toString()!);
        setTimeout(() => {
          //window.location.reload();
        }, 1000);
      } else {
        setIsNewAccountSelected(false);
      }
    }
  }, [connected]);

  useEffect(() => {
    console.log(wallet?.readyState);
  }, [wallet]);

  useEffect(() => {
    localStorage.removeItem(StorageKey.WALLET_NAME);
  }, []);

  return (
    <>
      {store.chatVisible ? <div className="ChatOpenner" onClick={() => reactChatStore.setIsChatVisible(reactChatStore.isChatVisible == true ? false : true)}>
        <div><i className="fas fa-comments"></i></div>
      </div> : <></>}

      {store.homeVisible ? <div className="ChatOpenner" onClick={() => navigate("/")}>
        <div><i className="fas fa-home"></i></div>
      </div> : <></>}

      <div className="ChatOpenner SettingsOpenner" onClick={() => setIsSettingVisible(true)}>
        <div><i className="fas fa-cog"></i></div>
      </div>

      <Modal
        visible={isSettingVisible}
        title={store.locale == LOCALES.ENGLISH ? "Site Settings" : "Настройки сайта"}
        onClose={() => setIsSettingVisible(false)}
        content={<>
          <div className="modal_text">
            
            <div className={styles.setting_block}>
              <div className={styles.setting_block__title}>{store.locale == LOCALES.RUSSIAN ? "Кошелек" : "Wallet using"}</div>
              <div className={styles.setting_block__content}>
                <WalletMultiButton />
              </div>
            </div>

            <div className={styles.setting_block}>
              <div className={styles.setting_block__title}>{store.locale == LOCALES.RUSSIAN ? "Язык сайта" : "Language"}</div>
              <div className={styles.setting_block__items}>
                <div onClick={() => onLocaleChange(LOCALES.RUSSIAN)} className={store.locale == LOCALES.RUSSIAN ? styles.active : ""}>RU</div>
                <div onClick={() => onLocaleChange(LOCALES.ENGLISH)} className={store.locale == LOCALES.ENGLISH ? styles.active : ""}>EN</div>
              </div>
            </div>
            
          </div>
          <div className="modal_action">
            <button onClick={() => setIsSettingVisible(false)}>{store.locale == LOCALES.RUSSIAN ? "Закрыть" : "Close"}</button>
          </div>
        </>} />
    </>
  )
}

export default observer(SettingsWidget)