import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import moment from "moment";
import { isNullOrUndefined } from "../ReactChatUtils";
import { IChat } from "../models/Chat";
import { StorageKey } from "../../../core/defaults";

export default class ReactChatStore {

  isAuth = false;
  token: string = "";
  userAuthData: any = {};
  isCloseSocket = false;

  chatTheme: "dark" | "light" = "dark";

  reactChatPosts: any[] = [];

  isChatVisible: boolean = false;
  isChatDisabled: boolean = false;
  isChatUnholded: boolean = false;
  allUnreadedMsgs: number = 0;
  selectedChat: IChat | null = null;

  constructor() {
    makeAutoObservable(this);
  }
  
  setAllUnreadedMsgs(state: number) {
    this.allUnreadedMsgs = state;
  }
  
  setIsChatDisabled(state: boolean) {
    this.isChatDisabled = state;
  }
  
  setIsChatUnholded(state: boolean) {
    this.isChatUnholded = state;
  }
  
  setIsChatVisible(state: boolean) {
    if (state != this.isChatVisible) {
      this.isChatVisible = state;
    }
  }

  setSelectedChat(chat: IChat | null) {
    this.selectedChat = chat;
  }
  
  setChatTheme(theme: "dark" | "light") {
    this.chatTheme = theme;
  }
  
  setUserData(data: any) {
    this.userAuthData = data;
  }

  setIsCloseSocket(data: boolean) {
    this.isCloseSocket = data;
  }
  
  setReactChatPosts(posts: any[]) {
    this.reactChatPosts = posts;
    localStorage.setItem(StorageKey.CHAT_POSTS, JSON.stringify(posts));
  }

  getReactChatPosts() {
    const posts = localStorage.getItem(StorageKey.CHAT_POSTS);
    if (!isNullOrUndefined(posts)) {
      let psts = JSON.parse(posts);
      this.reactChatPosts = psts;
      return psts;
    } else {
      return [];
    }
  }

  getLogin(login: string) {
    return `${login.substr(0, 4)}...${login.substr(login.length - 4, login.length)}`;
  }

  getMyLogin() {
    return this.isAuth ? `${this.userAuthData.login.substr(0, 4)}...${this.userAuthData.login.substr(this.userAuthData.login.length - 4, this.userAuthData.login.length)}` : ``;
  }

  getMyNfts() {
    return this.isAuth && !isNullOrUndefined(this.userAuthData.collection) ? this.userAuthData.collection.nfts : [];
  }

  isUserNotified() {
    return !isNullOrUndefined(this.userAuthData.socket_id) && this.userAuthData.socket_id != "";
  }
  
  getCollection() {
    if (this.userAuthData.collection != null) {
      return {
        size: this.userAuthData.collection.nfts.length,
        nfts: this.userAuthData.collection.nfts
      };
    } else {
      return {
        size: 0,
        nfts: []
      }
    }
  }

  setToken(token: string) {
    this.token = token;
  }

  getUserData() {
    return localStorage.getItem("user_data");
  }

  isUserAccessed() {
    const data = localStorage.getItem("user_data");
    if (isNullOrUndefined(data)) {
      return false;
    }

    try {
      const user = JSON.parse(data);
      if (user.collection.nfts.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }

  isUserAuthed() {
    const data = localStorage.getItem("user_data");
    if (isNullOrUndefined(data)) {
      return false;
    }
    return true;
  }

  isUserPaid() {
    return this.userAuthData.role == "USER_PAID";
  }

}