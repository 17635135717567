export const HomePageTranslations = {
  "EN": {
    home_title: "Investors Community",
    home_description: "<b>We invest in online and offline projects,</b> the profit from which will be distributed among all project holders.",
    home_top30: "TOP 30 HOLDERS",
    home_port_sum: "PORTFOLIO AMOUNT",
    home_port_cha: "CHANGE IN PORTFOLIO VALUE",
    home_project_advs: "Project Advantages",
    home_project_adv1_title: "1. your benefit",
    home_project_adv1_desc: '<span class="f1">80%</span> OF PROJECT FUNDS<br />GO TO THE COMMUNITY WALLET (DAO),<br /><span class="f2">WHICH YOU ARE A PARTICIPANT<br /> /span><br /><span class="f2">WHEN YOU BUY NFT</span> (MEANING YOU ARE<br />THE SHAREHOLDER OF THE GENERAL BANK)',
     home_project_adv2_title: "2. OUR<br />TRANSPARENCY",
     home_project_adv2_desc: 'EACH STAGE OF PROJECT DEVELOPMENT<br />IS FIXED IN A <span class="f3">ROADMAP</span>,<br />WHICH YOU CAN<br />CONTROL ON SOCIAL NETWORKS.<br /> <span class="f4">TOTAL TRANSPARENCY</span><br /><span class="f4">of all transactions</span>',
     home_project_adv3_title: "3. INCLUSIVE<br />COLLECTIONS",
     home_project_adv3_desc: 'The METABILDER PROJECT has absorbed<br />many<br/><span class="f5">unique images of animals</span><br/>and truly is<br/><span class="f4" >multicultural</span><br/><span class="f4">diverse environment</span><br/><span class="f4">in the metaverse</span>',
     home_nfts_title: "Each work in the NFT collection is unique",
     home_port_title: "Current Valuation<br/>NFT/M2E/CRYPTO Portfolio",
     home_blue_chips: "BLUE CHIPS",
     home_explore_port: "EXPLORE PORTFOLIO",
     home_soldout_history: "HISTORY SOLD OUT",
     home_updates: "* UPDATES EVERY 12 HOURS",
     home_roadmap_title: "Project Roadmap",
     home_drops_title: "individual branding of NFT-TOKEN",
     home_drops_order: "Order",
     home_drops_order_txt: "ORDER AND GET <b>BRANDED HERO</b>",
     home_drops_ttl: "DROPS",
     home_drops_price: "PRICE",
     home_team_title: "Project Team",
     home_team1_ttl: "ALEKSEY LOKONTSEV",
     home_team1_txt: "founder of such projects as<br />topgun.ru - 225 active outlets,<br />and colizeum.ru - 210 active outlets.<br/><br/>Both companies are world leaders<br/> in their industries",
     home_team2_ttl: "Maxim Konstantinov",
     home_team2_txt: "More than 15 years in investment,<br/>strategic planning, and<br/>concept development.<br/>Active crypto enthusiast since 2017.<br/>NFT since late 2020.<br/>Active member of many DAOs and DAAOs.<br/>Launched several NFT projects",
     home_team3_ttl: "Evgeny Kalyanov",
     home_team3_txt: "Since 2012, I have been doing art<br/>painting and graffiti. During this time<br/>I have collaborated with many large franchises<br/>. Drawings with my signature<br/>can be found in 28 cities of Russia.<br />At the moment I'm successfully developing<br/>in the direction of digital art",
     home_team4_ttl: "Oleg Naglets",
     home_team4_txt: "Successfully created and sold<br/>several projects. Proficient in sales and<br/>crisis management.<br/>Experienced marketing adventurer.<br/>Co-founder MetaBizClub",
     home_footer_copy: "all rights reserved",
     home_footer_contact: "contact person:"
  },
  "RU": {
    home_title: "Investors Community",
    home_description: "<b>Инвестируем в онлайн и оффлайн проекты,</b> прибыль от которых распределятся между всеми холдерами проекта.",
    home_top30: "ТОП-30 ХОЛДЕРОВ",
    home_port_sum: "СУММА ПОРТФЕЛЯ",
    home_port_cha: "ИЗМЕНЕНИЕ СТОИМОСТИ ПОРТФЕЛЯ",
    home_project_advs: "Достоинства проекта",
    home_project_adv1_title: "1. ваша выгода",
    home_project_adv1_desc: '<span class="f1">80%</span> ВХОДЯЩИХ В ПРОЕКТ СРЕДСТВ<br />ИДЕТ В КОШЕЛЕК КОМЬЮНИТИ (DAO),<br /><span class="f2">УЧАСТНИКОМ КОТОРОГО ВЫ СТАНОВИТЕСЬ</span><br /><span class="f2">ПРИ ПОКУПКЕ NFT</span> (ТО ЕСТЬ ВЫ являетесь<br />ДОЛЕВЫМ УЧАСТНИКОМ ОБЩЕГО БАНКА)',
    home_project_adv2_title: "2. НАША<br />ПРОЗРАЧНОСТЬ",
    home_project_adv2_desc: 'КАЖДЫЙ ЭТАП РАЗВИТИЯ ПРОЕКТА<br />ЗАФИКСИРОВАН В <span class="f3">ДОРОЖНОЙ КАРТЕ</span>,<br />КОНТРОЛЬ НАД КОТОРОЙ ВЫ МОЖЕТЕ<br />ОСУЩУСТВЛЯТЬ В СОЦИАЛЬНЫХ СЕТЯХ.<br /><span class="f4">АБСОЛЮТНАЯ ПРОЗРАЧНОСТЬ</span><br /><span class="f4">всех транзакций</span>',
    home_project_adv3_title: "3. ИНКЛЮЗИВНОСТЬ<br />КОЛЛЕКЦИИ",
    home_project_adv3_desc: 'ПРОЕКТ МЕТАБИЛДЕРОВ вобрал<br />в себя множество<br/><span class="f5">уникальных образов зверей</span><br/>и поистине является<br/><span class="f4">мультикультурной</span><br/><span class="f4">разносторонней средой</span><br/><span class="f4">в метавсленной</span>',
    home_nfts_title: "Каждая работа NFT коллекции уникальна",
    home_port_title: "Текущая оценка<br/>NFT/M2E/CRYPTO портфеля",
    home_blue_chips: "BLUE CHIPS",
    home_explore_port: "ИЗУЧИТЬ ПОРТФЕЛЬ",
    home_soldout_history: "ИСТОРИЯ SOLD OUT",
    home_updates: "* ОБНОВЛЯЕТСЯ КАЖДЫЕ 12 ЧАСОВ",
    home_roadmap_title: "Дорожная карта проекта",
    home_drops_title: "индивидуальное брендирование NFT-ТОКЕНА",
    home_drops_order: "Заказать",
    home_drops_order_txt: "ЗАКАЖИТЕ, И ПОЛУЧИТЕ <b>БРЕНДИРОВАННОГО ГЕРОЯ</b>",
    home_drops_ttl: "ДРОПЫ",
    home_drops_price: "ЦЕНА",
    home_team_title: "Команда проекта",
    home_team1_ttl: "АЛЕКСЕЙ ЛОКОНЦЕВ",
    home_team1_txt: "основатель таких проектов,<br />как topgun.ru — 225 действующих точек,<br />И colizeum.ru — 210 действующих точек.<br/><br/>Обе компании являются мировыми<br/>лидерами в своих отраслях",
    home_team2_ttl: "Максим Константинов",
    home_team2_txt: "Более 15 лет занимается инвестициями,<br/>стратегическим планированием и<br/>разработкой концепций.<br/>С 2017 года активный криптоэнтузиаст.<br/>С конца 2020 года в сфере NFT.<br/>Активный член многих DAO и DAAO.<br/>Запустил несколько NFT проектов",
    home_team3_ttl: "Евгений калаянов",
    home_team3_txt: "С 2012 года занимаюсь художественной<br/>росписью и граффити. За это время<br/>сотрудничал с многими крупными<br/>франшизами. Рисунки с моей подписью<br/>можно найти в 28 городах России.<br/>На данный момент успешно развиваюсь<br/>в направлении диджитал Арт",
    home_team4_ttl: "Олег Наглец",
    home_team4_txt: "Успешно создал и продал<br/>несколько проектов. Профи в продажах и<br/>кризис-управлении.<br/>Авантюрист-маркетолог со стажем.<br/>Co-founder MetaBizClub",
    home_footer_copy: "все права защищены",
    home_footer_contact: "контактное лицо:"
  }
}