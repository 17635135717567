import * as momenta from "moment-timezone";
import moment from "moment";

export const getDateFormat = (date: string, format: string = "DD MMM YYYY, HH:mm") => {
  return moment(date).add("+3", "hours").format(format);
}

export const getDateTSFormat = (ts: number, format: string = "DD MMM YYYY, HH:mm") => {
  return moment(ts).add("+3", "hours").format(format);
}

export const fixNumber = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

export const buildParams = (map: any) => {
  let query = "";
  for (let key in map) {
    if (query == "") {
      query = `?${key}=${map[key]}`;
    } else {
      query += `&${key}=${map[key]}`;
    }
  }
  return query;
}

export function urlify(text: string) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  })
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function declOfNum(n: number, text_forms: string[]) {  
  n = Math.abs(n) % 100; 
  var n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2]; }
  if (n1 > 1 && n1 < 5) { return text_forms[1]; }
  if (n1 == 1) { return text_forms[0]; }
  return text_forms[2];
}

export const ReactChatTranslations = {
  "EN": {
    react_chat_title: "React-Chat",
    react_chat_members_title: "Members Online",
    react_chat_locked_title: "Locked",
    react_chat_dialogs_title: "My Dialogs",
    react_chat_news_title: "News",
    react_chat_forall_title: "General Chat",
    react_chat_search_ph: "Name or Wallet",
    react_chat_list_empty: "List is empty",
    react_chat_menu_delete_chat: "Delete chat",
    react_chat_menu_clear_chat: "Clear all chat messages",
    react_chat_menu_hide: "Close",
    react_chat_menu_like: "Like",
    react_chat_menu_unlike: "Unlike",
    react_chat_menu_reply: "Reply",
    react_chat_menu_delete_msg: "Delete message",
    react_chat_menu_delete_user: "Delete all user messages",
    react_chat_menu_ban: "Ban",
    react_chat_menu_unban: "Unban",
    react_chat_menu_user: "user",
    react_chat_action1: "deleted all user messages",
    react_chat_action2: "cleared chat...",
    react_chat_action3: "blocked user",
    react_chat_action4: "unblocked user",
    react_chat_you_banned: "You are banned...",
    react_chat_challenge: "Combat platform is under development. Stay tuned for updates on our social media.",
    react_chat_holders: "Project holders",
    react_chat_creating: "Create chat",
    react_chat_online: "Online",
    react_chat_offline: "Offline",
    react_chat_list_global: "Global",
    react_chat_list_empty2: "Empty",
    react_chat_no_msgs: "No messages",
    react_chat_select_user: "Choose an interlocutor!"
  },
  "RU": {
    react_chat_title: "Реакт-Чат",
    react_chat_members_title: "Пользователи онлайн",
    react_chat_locked_title: "Заблокированные",
    react_chat_dialogs_title: "Мои диалоги",
    react_chat_news_title: "Новости",
    react_chat_forall_title: "Общий чат",
    react_chat_search_ph: "Имя или кошелек",
    react_chat_list_empty: "Список пуст",
    react_chat_menu_delete_chat: "Удалить чат",
    react_chat_menu_clear_chat: "Удалить все сообщения в чате",
    react_chat_menu_hide: "Закрыть",
    react_chat_menu_like: "Нравится",
    react_chat_menu_unlike: "Не нравится",
    react_chat_menu_reply: "Ответить",
    react_chat_menu_delete_msg: "Удалить сообщение",
    react_chat_menu_delete_user: "Удалить все сообщения пользователя",
    react_chat_menu_ban: "Заблокировать",
    react_chat_menu_unban: "Разблокировать",
    react_chat_menu_user: "пользователя",
    react_chat_action1: "удалил все сообщения пользователя",
    react_chat_action2: "очистил чат...",
    react_chat_action3: "заблокировал пользователя",
    react_chat_action4: "разблокировал пользователя",
    react_chat_you_banned: "Вы заблокированы...",
    react_chat_challenge: "Платформа боев находится на стадии разработки. Ожидайте новостей в наших соц. сетях.",
    react_chat_holders: "Холдеры проекта",
    react_chat_creating: "Создать чат",
    react_chat_online: "Онлайн",
    react_chat_offline: "Оффлайн",
    react_chat_list_global: "Глобальное",
    react_chat_list_empty2: "Пусто",
    react_chat_no_msgs: "Сообщений нет",
    react_chat_select_user: "Выберите собеседника!"
  }
};