export enum ControllerName {
  NEWS = "/news",
  VIDEOS = "/video",
  USERS = "/users",
  MERCH = "/merch",
  CORE = "/core",
  CHATS = "/chats",
  DROPS = "/drops",
  MBC = "/mbc",
  VOTINGS = "/votings",
  RAFFLES = "/raffles",
}

export enum AccountPageIndex {
  SUBSCRIBES = "subscribes",
  INFO = "info",
  VIEWS = "views",
  SEE_LATER = "see_later",
  SETTINGS = "settings"
}
