import React from "react";

const HomeWrapper = React.lazy(() => import("../../pages/HomeWrapper"));
const AccountEx = React.lazy(() => import("../../pages/Account/AccountEx"));
const Account = React.lazy(() => import("../../pages/Account/Account"));
const AccountTable = React.lazy(() => import("../../pages/Account/AccountTable"));
const AccountTest = React.lazy(() => import("../../pages/Account/AccountTest"));
const Drops = React.lazy(() => import("../../pages/Drops"));
const Portfolio = React.lazy(() => import("../../pages/Portfolio"));
const MintPage = React.lazy(() => import("../../pages/Mint"));
const CustomizeNFT = React.lazy(() => import("../../pages/Customize"));
const Merch = React.lazy(() => import("../../pages/Merch"));
const WhitePaper = React.lazy(() => import("../../pages/WhitePaper"));
const FootballClub = React.lazy(() => import("../../pages/FootballClub"));

export interface IRoute {
  path: string;
  component: React.ReactNode;
  exact?: boolean;
}

export enum RouteNames {
  HOME = '/',
  ACCOUNT = '/account',
  ACCOUNT_TABLE = '/account/table',
  ACCOUNT_TEST = '/account_test',
  ACCOUNT_VOTING = '/account/:name/:id',
  ACCOUNT_RAFFLE = '/account/:name/:id',
  ACCOUNT_EX = '/account/:userId',
  CUSTOMIZE = '/customize',
  DROPS = '/drops',
  FCLUB = '/football',
  DROPS_SET = '/drops/:set',
  PORTFOLIO = '/portfolio',
  MINT = '/mint/:name',
  MERCH = '/merch',
  WHITE_PAPER = '/whitepaper',
  WHITE_PAPER_PAGE = '/whitepaper/:page'
}

export const publicRoutes: IRoute[ ] = [
  { path: RouteNames.HOME, exact: true, component: <HomeWrapper /> },
  { path: RouteNames.CUSTOMIZE, exact: true, component: <CustomizeNFT /> },
  { path: RouteNames.FCLUB, exact: true, component: <FootballClub /> },
  { path: RouteNames.DROPS, exact: true, component: <Drops /> },
  { path: RouteNames.DROPS_SET, exact: true, component: <Drops /> },
  { path: RouteNames.PORTFOLIO, exact: true, component: <Portfolio /> },
  { path: RouteNames.MINT, exact: true, component: <MintPage /> },
  { path: RouteNames.ACCOUNT, exact: true, component: <Account /> },
  { path: RouteNames.ACCOUNT_TABLE, exact: true, component: <AccountTable /> },
  { path: RouteNames.ACCOUNT_TEST, exact: true, component: <AccountTest /> },
  { path: RouteNames.ACCOUNT_RAFFLE, exact: true, component: <Account /> },
  { path: RouteNames.ACCOUNT_VOTING, exact: true, component: <Account /> },
  { path: RouteNames.ACCOUNT_EX, exact: true, component: <AccountEx /> },
  { path: RouteNames.MERCH, exact: true, component: <Merch /> },
  { path: RouteNames.WHITE_PAPER, exact: true, component: <WhitePaper /> },
  { path: RouteNames.WHITE_PAPER_PAGE, exact: true, component: <WhitePaper /> },
]

export const twRoutes: IRoute[ ] = []
