import { ReactChatTranslations } from '../../components/react-chat/ReactChatUtils'
import { HomePageTranslations } from './components/HomeTranslations'
import { LOCALES } from './locales'
import { MenuTranslations } from './components/MenuTranslations'
import { MintTranslations } from './components/MintTranslations'

export const messages: any = {
  [LOCALES.ENGLISH]: {
    txt1: "Investors Community",
    txt2: "<b>Our mission</b> is to jointly invest in web 3.0 projects and distribute profits among club members, as well as to study everything new and innovative, create professional networking.",
    txt3: "All rights reserved",
    txt4: "Contact person:",
    //buyonme: "BUY ON MAGIC EDEN",
    buyonme: "BUY METABUILDER",
    totaltoday: "CHANGE IN PORTFOLIO VALUE",
    update: "* updated every 12 hours",
    scanwall: "scan wallet with assets",
    soldout: "SOLD OUT HISTORY",
    menu: {
      item1: "Home",
      item2: "Project Advantages",
      item3: "Uniqueness",
      item4: "Сurrent portfolio valuation",
      item5: "Roadmap",
      item6: "Team",
      item7: "FAQ"
    },
    team: {
      aa: {
        name: "ALEKSEY LOKONCEV",
        desc: "THE FOUNDER OF THE FOLLOWING PROJECTS<br>TOPGUN.RU — 225 OPERATING POINTS,<br>COLIZEUM.RU — 210 OPERATING POINTS<br><br>BOTH COMPANIES ARE WORLD LEADERS IN THEIR RESPECTIVE INDUSTRIES"
      },
      mk: {
        name: "MAXIM KONSTANTINOV",
        desc: "MORE THAN 15 YEARS OF INVESTMENT EXPERIENCE, STRATEGIC PLANNING AND CONCEPT DEVELOPMENT. SINCE 2017 AN ACTIVE CRYPTO ENTHUSIAST. SINCE THE END OF 2020 IN NFT SECTOR. ACTIVE MEMBER OF MANY DAO AND DAAO. LAUNCHED SEVERAL NFT PROJECTS"
      },
      ek: {
        name: "EVGENY KALAYANOV",
        desc: "SINCE 2012 I HAVE BEEN DOING ARTISTIC PAINTING AND GRAFFITI. DURING THIS TIME, I HAVE BEEN WORKING WITH A LOT OF BIG FRANCHISES. DRAWINGS WITH MY SIGNATURE CAN BE FOUND IN 28 CITIES OF RUSSIA. AT THE MOMENT I AM DEVELOPING SUCCESSFULLY IN THE DIGITAL ART"
      },
      on: {
        name: "OLEG NAGLETS",
        desc: "CREATED AND SOLD SEVERAL PROJECTS SUCCESSFULLY. <br>PRO IN SALES AND CRISIS MANAGEMENT. <br>EXPERIENCED ADVENTUROUS MARKETEER.<br>CO-FOUNDER OF METABIZCLUB"
      }
    },
    search_city: "Сity name",
    ...ReactChatTranslations.EN,
    ...HomePageTranslations.EN,
    ...MenuTranslations.EN,
    ...MintTranslations.EN
  },
  [LOCALES.RUSSIAN]: {
    txt1: "Investors Community",
    txt2: "<b>наша миссия -</b> совместное инвестирование в web 3.0 проекты и распределение прибыли между членами клуба, а также изучение всего нового и инновационного, создание профессионального нетворкинга.",
    txt3: "все права защищены",
    txt4: "контактное лицо:",
    //buyonme: "КУПИТЬ НА MAGIC EDEN",
    buyonme: "Купить МетаБилдера",
    totaltoday: "ИЗМЕНЕНИЕ СТОИМОСТИ ПОРТФЕЛЯ",
    update: "* обновляется каждые 12 часов",
    scanwall: "сканировать кошелёк с активами",
    soldout: "ИСТОРИЯ SOLD OUT",
    menu: {
      item1: "Главная",
      item2: "Достоинства проекта",
      item3: "Уникальность",
      item4: "Текущая оценка портфеля",
      item5: "Дорожная карта",
      item6: "Команда",
      item7: "FAQ"
    },
    team: {
      aa: {
        name: "АЛЕКСЕЙ ЛОКОНЦЕВ",
        desc: "основатель таких проектов,<br>как topgun.ru — 225 действующих точек,<br>И colizeum.ru — 210 действующих точек.<br><br>Обе компании являются мировыми<br>лидерами в своих отраслях"
      },
      mk: {
        name: "Максим Константинов",
        desc: "Более 15 лет занимается инвестициями,<br>стратегическим планированием и<br>разработкой концепций.<br>С 2017 года активный криптоэнтузиаст.<br>С конца 2020 года в сфере NFT.<br>Активный член многих DAO и DAAO.<br>Запустил несколько NFT проектов"
      },
      ek: {
        name: "Евгений калаянов",
        desc: "С 2012 года занимаюсь художественной<br>росписью и граффити. За это время<br>сотрудничал с многими крупными<br>франшизами. Рисунки с моей подписью<br>можно найти в 28 городах России.<br>На данный момент успешно развиваюсь<br>в направлении диджитал Арт"
      },
      on: {
        name: "Олег Наглец",
        desc: "Успешно создал и продал<br>несколько проектов. Профи в продажах и<br>кризис-управлении.<br>Авантюрист-маркетолог со стажем.<br>Co-founder MetaBizClub"
      }
    },
    search_city: "Наименование города",
    ...ReactChatTranslations.RU,
    ...HomePageTranslations.RU,
    ...MenuTranslations.RU,
    ...MintTranslations.RU
  }
}