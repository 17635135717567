import React, { createContext } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import Store from './core/store';

import './index.css';
import ReactChatStore from './components/react-chat/store';

interface State {
  store: Store;
  reactChatStore: ReactChatStore;
}

const store = new Store();
const reactChatStore = new ReactChatStore();

export const Context = createContext<State>({store, reactChatStore});

const root = createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Context.Provider value={{store, reactChatStore}}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Context.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();