import './App.scss';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { observer } from "mobx-react-lite";
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import { isNullOrUndefined } from './core/utils';
import { ApiService } from './core/services/api';
import { Context } from '.';
import { Logger } from './core/logger';
import { useFetching } from './core/hooks/useFetching';
import { LOCALES } from './core/i18n/locales';
import { messages } from './core/i18n/translations';
import AppRouter from './components/AppRouter';
import ReactChatComponent from './components/react-chat/ReactChat';
import * as anchor from '@project-serum/anchor';
import 'react-toastify/scss/main.scss';
import Modal from './components/Modal';
import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ConnectionProvider, useAnchorWallet, useWallet, WalletProvider } from '@solana/wallet-adapter-react';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { SafePalWalletAdapter } from '@solana/wallet-adapter-safepal';
import { SolflareWalletAdapter } from '@solana/wallet-adapter-solflare';
import { MathWalletAdapter } from '@solana/wallet-adapter-mathwallet';
import { TrustWalletAdapter } from '@solana/wallet-adapter-trust';
import { LedgerWalletAdapter } from '@solana/wallet-adapter-ledger';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import SettingsWidget from './pages/components/SettingsWidget';
import { StorageKey } from './core/defaults';

import '@solana/wallet-adapter-react-ui/styles.css';
import useWindowDimensions from './core/hooks/useWindowDimensions';

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connectionAc = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl('devnet'),
);

const App: FC = () => {
  const { store, reactChatStore } = useContext(Context);
  
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const {width, height} = useWindowDimensions();
  
  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new SafePalWalletAdapter(),
    new SolflareWalletAdapter(),
    new LedgerWalletAdapter(),
    new MathWalletAdapter(),
    new TrustWalletAdapter()
  ], []);

  const changeLocale = (locale: string) => {
    store.setLocale(locale);
    localStorage.setItem(StorageKey.LANG, locale == LOCALES.ENGLISH ? "en" : "ru");
  }

  const [allUnreadedMsgs, setAllUnreadedMsgs] = useState(0);

  const [fetchUnreads, isUnreadsLoading, errorUnreads] = useFetching(async () => {
    const unreadedRes = await ApiService.chats.getMyUnreaded(store.userAuthData.id);

    let unread = 0;
    unreadedRes.data.unreads.forEach((v: any) => {
      unread += v.unread_msgs.length;
      unread += v.newLikes.length;
      unread += v.newReactions.length;
    });

    setAllUnreadedMsgs(unread);
  });

  const [fetchUniverses] = useFetching(async () => {
    const exists = localStorage.getItem(StorageKey.COLL_FILTER);
    if (!isNullOrUndefined(exists) && exists != "") {
      store.setCollectionFilter(JSON.parse(exists));
    }

    const res = await ApiService.drops.getUniverses();
    console.log(res);
    store.setCollectionFilter(res.data.universes);
  });

  const [refreshUserData] = useFetching(async () => {
    let data: any = localStorage.getItem("user_data");
    if (!isNullOrUndefined(data)) {
      Logger.slog(`User authorized!`, data.id);
      data = JSON.parse(data);
      const res = await ApiService.mbc.signIn({login: data.login});
      Logger.slog("REAUTH USER!", res.data.user.id);
      localStorage.setItem(StorageKey.USER_AUTHED, "true");
      localStorage.setItem(StorageKey.USER_DATA, JSON.stringify(res.data.user));
      localStorage.setItem(StorageKey.USER_TOKEN, res.data.token);
      store.setUserData(res.data.user);
      store.setToken(res.data.token);
      reactChatStore.setUserData(res.data.user);
      reactChatStore.setToken(res.data.token);
    }
  });

  useEffect(() => {
    refreshUserData();
    fetchUnreads();
    fetchUniverses();

    const lang = localStorage.getItem(StorageKey.LANG);
    if (!isNullOrUndefined(lang)) {
      if (lang == "ru") {
        changeLocale(LOCALES.RUSSIAN);
      }
    }

    if (width <= 1000) {
      store.setIsMobile(true);
    }

    window.addEventListener('resize', () => {
      if (window.innerWidth <= 1000) {
        store.setIsMobile(true);
      } else {
        store.setIsMobile(false);
      }
    });
  }, []);

  return (
    <>
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <IntlProvider messages={messages[store.locale]} locale={store.locale} defaultLocale={LOCALES.ENGLISH}>
            <AppRouter />

              <SettingsWidget
                onLocaleChange={(l: string) => changeLocale(l)} />
        
              <ToastContainer />
            </IntlProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </>
  );
};

export default observer(App);